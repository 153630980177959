<template>
    <div class="mt-4">
        <Title title="FICHA_SAUDE.OBSERVACOES" />
        <b-row class="mt-4">
            <b-col>
                <InputTextArea ref="fichaSaude.observacoes" required v-model="fichaSaude.observacoes" :label="$t('FICHA_SAUDE.OBSERVACOES_OUTRAS')" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
import InputTextArea from '@/components/inputs/InputTextArea.vue';
export default{
    props: ['fichaSaude'],
    components:{
        Title,
        InputTextArea,
    }
}
</script>