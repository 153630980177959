<template>
    <div class="mt-4">
        <Title title="FICHA_SAUDE.ALERGIAS"/>
        <b-row>
            <b-col class="col-12">
                <input-checkbox ref="fichaSaude.alergia" required v-model="fichaSaude.alergia" :label="$t('FICHA_SAUDE.ALERGIA_CONFIRMACAO')" />
            </b-col>
            <b-col>
                <input-text ref="fichaSaude.especificaoAlergia" required v-model="fichaSaude.especificaoAlergia" :label="$t('FICHA_SAUDE.ALERGIA_QUAIS')" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputText from '@/components/inputs/InputText.vue';
export default{
    props: ['fichaSaude'],
    components:{
        Title,
        InputCheckbox,
        InputText
    }
}
</script>