import ApiService from "@/common/api/api.service";

const FichaSaudeService = {
    async salvarFicha(fichaSaude){
        let {data} = await ApiService.post(`FichaSaude`,fichaSaude)
        return data
    },
    async atualizarFicha(){
        let {data} = await ApiService.put(`FichaSaude`)
        return data
    }
}

export default FichaSaudeService;