<template>
    <div class="mt-4">
        <Title title="FICHA_SAUDE.INTOLERANCIAS" />
        <b-row>
            <b-col class="col-3">
                <input-checkbox ref="fichaSaude.intoleranciaLactose" required v-model="fichaSaude.intoleranciaLactose" :label="$t('FICHA_SAUDE.INTOLERANCIAS_LACTOSE')" />
            </b-col>
            <b-col class="col-3">
                <input-checkbox ref="fichaSaude.intoleranciaGluten" required v-model="fichaSaude.intoleranciaGluten" :label="$t('FICHA_SAUDE.INTOLERANCIAS_GLUTEN')" />
            </b-col>
            <b-col class="col-3">
                <input-checkbox ref="fichaSaude.refluxo" required v-model="fichaSaude.refluxo" :label="$t('FICHA_SAUDE.REFLUXO')" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
export default{
    props: ['fichaSaude'],
    components:{
        Title,
        InputCheckbox
    }
}
</script>