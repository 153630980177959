<template>
    <topbar
        :titulo="$t('ACADEMICO.REMATRICULA')"
        :subtitulo="$t('ACADEMICO.REMATRICULA')"
        :breadcrumbs="breadcrumbs"
    >
        <template #header>
            <b-button variant="secondary" @click="irPara">{{
                $t('GERAL.VOLTAR')
            }}</b-button>
        </template>
    </topbar>
</template>
<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import rotas from '@/common/utils/rotas';

// Components:
export default {
    components: { Topbar },
    props: {
        podeRematricular: { type: Boolean, default: false },
    },
    data() {
        return {
            breadcrumbs: [
                { titulo: this.$t('ACADEMICO.ACADEMICO') },
                { titulo: this.$t('ACADEMICO.MATRICULA') },
                { titulo: this.$t('ACADEMICO.REMATRICULA') },
            ],
        };
    },
    methods: {
        // FUNÇÕES CRUD:
        irPara() {
            rotas.retornar();
        },
    },
};
</script>
