<template>
    <div>
        <titulo-rematricula />
        <b-tabs>
            <b-tab class="pt-3" :title="`1 - ${$t('ACADEMICO.DADOS_GERAIS')}`">
                <b-row class="mt-2">
                    <b-col class="col-12">
                        <dados-gerais :dados="matricula" />
                    </b-col>
                    <b-col class="col-12 mt-4">
                        <responsaveis :responsaveis="matricula.vinculos" />
                    </b-col>
                    <div class="col-12 mt-4">
                        <endereco :enderecos="matricula.enderecos" />
                    </div>
                </b-row>
            </b-tab>

            <b-tab
                class="pt-3"
                :title="`2 - ${$t('ACADEMICO.DADOS_DE_SAUDE')}`"
            >
                <span class="btn-outline-danger d-block mb-4">
                    {{ $t('ACADEMICO.CONFIRMAR_DADOS_DE_SAUDE') }}
                </span>
                <ficha-saude
                    @ficha-saude="getFichaSaude"
                    @confirmar="confirmacaoFichaSaude"
                />
            </b-tab>

            <b-tab
                :disabled="desabilitar.tabRematricula"
                class="pt-3"
                :title="`3 - ${$t('ACADEMICO.TERMOS')}`"
            >
                <b-row class="mt-2">
                    <b-col class="col-12 mt-4">
                        <extra-classe />
                    </b-col>
                    <b-col class="col-12 mt-4">
                        <aceitar-termos
                            @baixa-contrato="baixarContrato"
                            @aceitar="aceitarTermos"
                        />
                    </b-col>
                </b-row>
                <!-- botões de ação -->
                <b-row>
                    <b-col class="mt-3 col-12 d-flex justify-content-center">
                        <router-link to="/protocolo">
                            <b-button
                                class="mr-0 mr-md-3"
                                variant="secondary"
                                >{{ $t('MATRICULAS.REPORTAR_ERRO') }}</b-button
                            >
                        </router-link>
                        <b-button
                            @click="confirmarRematricula"
                            :disabled="btnFazerRematricula"
                            variant="primary"
                            >{{ $t('MATRICULAS.FAZER_REMATRICULA') }}</b-button
                        >
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import ContratoService from '@/common/services/contratos/contratos.service.js';
import rotas from '@/common/utils/rotas';
import pdf from '@/common/utils/pdf';
import mensagem from '@/common/utils/mensagem';

// services
import FichaSaudeService from '@/common/services/ficha-saude/ficha-saude.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';

// import breadcrumb from "@/common/utils/breadcrumb"

// import BaseCard from '@/components/card/BaseCard.vue';
import TituloRematricula from './components/TituloRematricula';
import DadosGerais from '@/views/academico/matriculas/components/DadosGerais';
import Responsaveis from '@/views/academico/matriculas/components/Responsaveis';
import Endereco from '@/views/academico/matriculas/components/Endereco';
import FichaSaude from './components/fichaSaude/Index';
import AceitarTermos from './components/AceitarTermos';
import ExtraClasse from './components/ExtraClasse';

export default {
    components: {
        TituloRematricula,
        DadosGerais,
        Endereco,
        Responsaveis,
        FichaSaude,
        AceitarTermos,
        ExtraClasse,
    },
    data() {
        return {
            matricula: [],
            contratoMatriculaId: '',
            fichaSaude: [],
            desabilitar: {
                btnFazerRematricula: true,
                tabRematricula: true,
            },
        };
    },
    mounted() {
        this.getDados();
        this.obterContratoId();
    },
    computed: {
        btnFazerRematricula() {
            return this.desabilitar.btnFazerRematricula;
        },
    },
    methods: {
        getDados() {
            // buscando os dados da matrícula do param da rota pelo store:
            this.$store.state.matriculas.matriculas.forEach((element) => {
                if (element.id == this.$route.params.id) {
                    this.matricula = element;
                }
            });

            // impedindo de prosseguir se não encontrar dados na matrícula
            if (!Object.keys(this.matricula).length) {
                return mensagem.erro(
                    'Ooops!',
                    'Não foi possível puxar os dados desta matrícula no momento, aguarde ou contate o desenvolvedor'
                );
            }

            // GAMBIARRA ~(^-^)~ -> buscando o endereço enquanto o endpoint não retorna:
            PessoaService.buscarPorId(this.matricula.pessoaId).then(
                ({ data }) => {
                    this.matricula.pessoa = JSON.parse(JSON.stringify(data));

                    this.matricula.enderecos = this.matricula.pessoa.enderecos;
                    this.matricula.enderecos.forEach((element) => {
                        element.principal == true
                            ? (element.principalTexto = 'Sim')
                            : (element.principalTexto = 'Não');
                    });
                }
            );
        },
        getFichaSaude(fichaSaude) {
            this.fichaSaude = fichaSaude;
        },
        baixarContrato() {
            this.$store.dispatch(START_LOADING);
            ContratoService.visualizarContratoPDF(this.matricula.id)
                .then(({ data }) => {
                    if (data.documento) {
                        //converte os bytes em array:
                        let bytes = pdf.base64ToArrayBuffer(data.documento);

                        if (bytes) {
                            // converte o array em blob
                            let blob = new Blob([bytes], {
                                type: 'application/pdf',
                            });

                            // faz download do PDF:
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = data.nomeArquivo;
                            link.click();
                        }
                    }
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        obterContratoId() {
            ContratoService.buscarPorMatricula(this.matricula.id).then(
                ({ data }) => {
                    this.contratoMatriculaId = data.itens[0]['id'];
                }
            );
        },
        async confirmarRematricula() {
            try {
                this.$store.dispatch(START_LOADING);
                // Rematrícula do contrato
                const { data } = await ContratoService.rematriculaLote({
                    matriculaId: this.matricula.id,
                });

                if (data.id)
                    await FichaSaudeService.salvarFicha({
                        ...this.fichaSaude,
                        contratoId: data.id,
                    });

                // Execução bem-sucedida
                this.$store.dispatch(STOP_LOADING);
                await mensagem.sucesso('Rematrícula efetuada!');
                rotas.retornar();
            } catch (err) {
                mensagem.showErrors(err);
            } finally {
                this.$store.dispatch(STOP_LOADING);
            }
        },

        confirmacaoFichaSaude(checkboxValue) {
            this.desabilitar.tabRematricula = !checkboxValue;
        },

        aceitarTermos(checkboxValue) {
            this.desabilitar.btnFazerRematricula = !checkboxValue;
        },
    },
};
</script>

<style>
#rematriculaPrevBtn {
    display: none;
}
</style>
