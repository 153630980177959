<template>
    <div>
        <Title title="FICHA_SAUDE.ACEITAR_TERMOS"/>
        <div class="mt-4">
            <p>Ao clicar no botão azul claro abaixo você tem acesso ao contrato:</p>
            <b-button @click="baixarContrato" variant="info" class="w-100">{{ $t('MATRICULAS.BAIXAR_CONTRATO') }}</b-button>
            <input-checkbox
            v-model="checked"
            :label="$t('MATRICULAS.ACEITO_TERMOS')"
            class="mt-2 d-flex justify-content-center"
            />
            <p class="pt-4">
                Estando de acordo, clique no checkbox para aceitar os termos e
                clique no botão azul escrito "Fazer rematrícula".
                
                Caso encontre qualquer erro no presente documento ou de dados neste formulário,
                clique no botão vermelho para abrir um protocolo e solicitar ajuste ou,
                entre em contato diretamente com o CEJ.
            </p>
        </div>
    </div>
</template>
<script>
import Title from "@/components/Title.vue";
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
export default {
    components: { InputCheckbox, Title },
    data(){
        return{
            checked: false
        }
    },
    watch: {
        checked(){
            this.$emit('aceitar', this.checked)
            return this.checked
        }
    },
    methods: {
        baixarContrato(){
            this.$emit('baixa-contrato')
        }
    }
}
</script>