<template>
    <div>
        <p>{{ $t('FICHA_SAUDE.SOBRE') }}</p>
        <plano-saude :fichaSaude="fichaSaude" />
        <intolerancias :fichaSaude="fichaSaude" />
        <medicacoes :fichaSaude="fichaSaude" />
        <alergias :fichaSaude="fichaSaude" />
        <deficiencias :fichaSaude="fichaSaude" />
        <observacoes :fichaSaude="fichaSaude" />
        <confirmacao-form @confirmar="confirmacao" />
    </div>
</template>
<script>
import breadcrumb from "@/common/utils/breadcrumb"

import PlanoSaude from './PlanoSaude';
import Intolerancias from './Intolerancias';
import Medicacoes from './Medicacoes';
import Alergias from './Alergias';
import Deficiencias from './Deficiencias';
import Observacoes from './Observacoes';
import ConfirmacaoForm from './ConfirmacaoForm';
export default{
    components: {
        PlanoSaude,
        Intolerancias,
        Medicacoes,
        Alergias,
        Deficiencias,
        Observacoes,
        ConfirmacaoForm
    },
    mounted(){
        breadcrumb.definir(this.$store, [{ titulo: 'Ficha de Saúde'}])
    },
    data(){
        return{
            fichaSaude: {
                planoDeSaude: '',
                numeroCarteirinha: '',
                diabetes: false,
                medicamentoContinuo: false,
                nomeMedicamento: '',
                motivoMedicamento: '',
                alergia: false,
                especificaoAlergia: '',
                intoleranciaLactose: false,
                intoleranciaGluten: false,
                refluxo: false,
                deficiencia: false,
                especificacaoDeficiencia: '',
                tiposDeficiencias: '',
                observacoes: '',
                contratoId: '',
            }
        }
    },
    watch: {
        fichaSaude: {
            handler() {
                this.$emit('ficha-saude', this.fichaSaude)
            },
            deep: true
        }
    },
    methods: {
        confirmacao(checkboxValue){
            this.$emit('confirmar',checkboxValue)
        }
    }
}
</script>