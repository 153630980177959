<template>
    <div>
        <Title :title="$t('ACADEMICO.EXTRA_CLASSE')" />
        <b-row class="d-flex justify-content-end mt-3">
            <b-col>
                <p>
                    <b> {{ $t('ACADEMICO.EXTRA_CLASSE_INFO') }}:</b>
                </p>
                <p>
                    {{ $t('ACADEMICO.EXTRA_CLASSE_INFO') }}
                </p>
            </b-col>
            <b-col class="text-right" md="12">
                <b-button @click="baixarFicha" variant="info" class="w-100">
                    {{ $t('ACADEMICO.BAIXAR_FICHA_REMATRICULA') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// import fichaCultural from '@/assets/files/ficha-inscricao/ficha inscricao-extraclasse-cultural-2022.pdf'

// Components:
import Title from '@/components/Title.vue';

export default {
    props: ['dados'],
    components: {
        Title,
    },
    methods: {
        baixarFicha() {
            // TODO: comentado enquanto está dando erro ฅ⁠^⁠•⁠ﻌ⁠•⁠^⁠ฅ
            // var link = document.createElement('a')
            // link.href = fichaCultural
            // link.download = `ficha-inscricao-cultural-2022`
            // link.click()
        },
    },
};
</script>
