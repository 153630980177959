<template>
    <div>
        <b-row>
            <b-col class="d-flex">
                <input-checkbox v-model="checked" />
                <p>{{ $t('FICHA_SAUDE.DECLARAR_VERDADEIRO') }}</p>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
export default {
    components: { InputCheckbox },
    data() {
        return {
            checked: false,
        };
    },
    watch: {
        checked: {
            handler(value) {
                this.$emit('confirmar', value);
                return value;
            },
        },
    },
};
</script>
