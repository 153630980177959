<template>
    <div class="mt-4">
        <Title title="FICHA_SAUDE.MEDICACOES" />
        <b-row>
            <b-col class="col-12">
                <input-checkbox ref="fichaSaude.medicamentoContinuo" required v-model="fichaSaude.medicamentoContinuo" class="mt-1" :label="$t('FICHA_SAUDE.MEDICACOES_CONFIRMACAO')" />
            </b-col>
            <b-col>
                <input-text ref="fichaSaude.nomeMedicamento" required v-model="fichaSaude.nomeMedicamento" :label="$t('FICHA_SAUDE.MEDICACOES_QUAIS')" />
            </b-col>
            <b-col>
                <input-text ref="fichaSaude.motivoMedicamento" required v-model="fichaSaude.motivoMedicamento" :label="$t('FICHA_SAUDE.MEDICACOES_MOTIVO')" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputText from '@/components/inputs/InputText.vue';
export default{
    props: ['fichaSaude'],
    components:{
        Title,
        InputCheckbox,
        InputText
    }
}
</script>