<template>
    <div class="mt-4">
        <Title title="FICHA_SAUDE.DEFICIENCIAS" />
        <b-row>
            <b-col>
                <input-checkbox ref="fichaSaude.deficiencia" required v-model="fichaSaude.deficiencia" class="mt-1" :label="$t('FICHA_SAUDE.DEFICIENCIAS_CONFIRMACAO')" />
                <input-text ref="fichaSaude.especificacaoDeficiencia" required v-model="fichaSaude.especificacaoDeficiencia" :label="$t('FICHA_SAUDE.DEFICIENCIAS_ESPECIFIQUE')" :placeholder="$t('FICHA_SAUDE.DEFICIENCIAS_DESCRICAO_CID')" />
            </b-col>
            <b-col>
                <p>{{ $t('FICHA_SAUDE.DEFICIENCIAS_SELECIONE') }}</p>
                <b-row>
                    <b-col>
                        <input-checkbox :label="$t('FICHA_SAUDE.DEFICIENCIA_FISICA')" />
                        <input-checkbox :label="$t('FICHA_SAUDE.DEFICIENCIA_VISUAL')" />
                        <input-checkbox :label="$t('FICHA_SAUDE.DEFICIENCIA_AUDITIVA')" />
                    </b-col>
                    <b-col>
                        <input-checkbox :label="$t('FICHA_SAUDE.DEFICIENCIA_INTELECTUAL')" />
                        <input-checkbox :label="$t('FICHA_SAUDE.DEFICIENCIA_PSICOSSOCIAL')" />
                        <input-checkbox :label="$t('FICHA_SAUDE.DEFICIENCIA_MULTIPLA')" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import InputText from '@/components/inputs/InputText.vue';
export default{
    props: ['fichaSaude'],
    components:{
        Title,
        InputCheckbox,
        InputText
    }
}
</script>