<template>
    <div class="mt-4">
        <Title title="FICHA_SAUDE.PLANO_SAUDE" />
        <b-row>
            <input-text class="col-6" ref="fichaSaude.planoDeSaude" required v-model="fichaSaude.planoDeSaude" :label="$t('FICHA_SAUDE.PLANO_SAUDE')" :placeholder="$t('FICHA_SAUDE.PLANO_SAUDE_PLACEHOLDER')"/>
            <input-text class="col-6" ref="fichaSaude.numeroCarteirinha" required v-model="fichaSaude.numeroCarteirinha" :label="$t('FICHA_SAUDE.PLANO_SAUDE_NUMERO_CARTEIRINHA')" :placeholder="$t('FICHA_SAUDE.PLANO_SAUDE_NUMERO_CARTEIRINHA_PLACEHOLDER')"/>  
        </b-row>
    </div>
</template>
<script>
import Title from '@/components/Title.vue';
import InputText from '@/components/inputs/InputText.vue';
export default {
    props: ['fichaSaude'],
    components: {
        Title,
        InputText
    }
}
</script>